import React from "react";

export default function SixthComp() {
  return (
    <div className="bg-darkBlue">
      <div className="mainDiv flex lg:flex-row flex-col 2xl:w-7/12 w-full mx-auto justify-evenly text-white py-20 px-4">
        <div className="left lg:w-5/12 w-full">

        <h1 className="font-bold text-4xl leading-relaxed">
          Translate your transcript into over <span className="text-lightBlue"> 120 languages </span>and
          950+ language pairs.
        </h1>
        <p className="font-extralight leading-loose pt-8">
          Your transcript can be translated into over 120 languages and 950+
          language pairs. We can translate transcripts to English from Arabic,
          French from German, Portuguese from Spanish and more. We support rare
          and hard-to-find languages as well, so you can be sure to have your
          pick from our large repertoire of languages.
        </p>
        </div>
        <div className="right lg:w-5/12 w-full lg:pt-0 pt-5">
          <div className="div flex justify-evenly lg:gap-x-32">
            <div className="listOne ">
                <ul className="list-disc font-extralight">
                    <li>Chinese(traditional)</li>
                    <li className="pt-4">Chinese(simplified)</li>
                    <li className="pt-4">Korean</li>
                    <li className="pt-4">Arabic</li>
                    <li className="pt-4">Vietnamese</li>
                </ul>
            </div>
            <div className="listTwo">
            <ul className="list-disc font-extralight">
                    <li className="">French</li>
                    <li className="pt-4">Japanese</li>
                    <li className="pt-4">Portuguese</li>
                    <li className="pt-4">Spanish</li>
                    <li className="pt-4">German</li>
                </ul>
            </div>
          </div>
            <p className="pt-10 font-extralight">If youâ€™re interested in translating now, here are our languages available.</p>
            <a className="font-sm pt-6" href="">SEE OUR SUPPORTED LANGUAGES</a>
        </div>
      </div>
    </div>
  );
}
