import React from "react";

export default function FifthComp() {
  return (
    <div className="bg-midBlue text-white">
      <h1 className="text-center text-4xl font-bold py-12">
        We Translate all kinds of Academic Transcripts
      </h1>
      <div className="mainContainer xl:w-8/12  w-full flex flex-wrap mx-auto justify-evenly py-12">
        <div className="left lg:w-4/12 w-full px-6 lg:px-0">
          <img
            src="https://tomedes.gumlet.io/frontend/images/services/digital.webp"
            alt=""
          />
          <h1 className="text-2xl font-bold text-orange py-8">High School Transcript</h1>
          <p className="font-extralight">
            Our high school transcript translation services will conquer your
            fears of submitting a transcript in a language not recognized by
            your future college. Your aspirations to obtain acceptance into your
            dream college or university will be heightened by our transcript
            translation service.
          </p>
        </div>
        <div className="left lg:w-4/12 w-full px-6 lg:px-0 pt-5 lg:pt-0">
          <img
            src="https://tomedes.gumlet.io/frontend/images/services/traditional.webp"
            alt=""
          />
          <h1 className="text-2xl font-bold text-orange py-8">University Transcript</h1>
          <p className="font-extralight">
            When it comes to undergraduate and graduate transcripts, transcript
            translation services can accommodate all the requirements of your
            professional career institution. Applying to jobs has never been
            easier through transcript translation services that are guaranteed
            to be accepted by your dream institution.
          </p>
        </div>
      </div>
    </div>
  );
}
