import React from "react";

export default function LocalizationMidText({ orangeMidText, content, backgroundCol, fText }) {
  return (
    <div className={` ${backgroundCol ? backgroundCol : "bg-midBlue"}`}>
      <div className="container mx-auto">
        <div className={` flex items-center justify-center`}>
          <div className="textOnly  w-full  px-4 lg:px-0 text-center py-28 text-white flex items-center justify-center md:w-10/12">
            <p className="font-normal text-[26px] md:text-[26px] leading-10 font-secondary">
              {fText ? fText : "Our"} <span className="text-orange font-medium">{orangeMidText ? orangeMidText : "localization"}</span>{" "}
              {content
                ? content
                : "services help brands and companies around the world  reach their business objectives and further their global growth through language solutions. We broaden the horizons of any company looking to venture into international markets."}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
