import React, { useState } from "react";
import { useDocumentupload } from "hooks/Forms/useDocumentupload";
import Loader from "components/loader";
import ThankYouPopup from "components/popup/thankYouPopup";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import formCommonPostData from "utils/form-common-post-data";
import getCookie from "utils/getCookie";

function TechnicalDocument({
  firstHead,
  headColor,
  colHead,
  secondHead,
  backgroundCol,
  para,
  setOpen,
}) {
  const [thankOpen, setThankOpen] = useState(false);
  const [imageupload, setImageUpload] = useState("");
  const [value, setValue] = useState("");
  const location = useLocation();

  const { status, mutateAsync } = useDocumentupload();

  const onFileUpload = () => {
    if (value === "") {
      alert("The email field is required.");
    } else if (imageupload === "") {
      alert("Please upload document");
    } else {
      const formData = new FormData();
      formData.append("email", value);
      formData.append("document", imageupload);
      formData.append("pagelink", location.href);
      formData.append("source", getCookie("utm_source"));
      formData.append("utm_campaign", getCookie("utm_campaign"));
      formData.append("utm_agid", getCookie("utm_agid"));
      formData.append("utm_term", getCookie("utm_term"));
      formData.append("creative", getCookie("creative"));
      formData.append("device", getCookie("device"));
      formData.append("placement", getCookie("placement"));
      formData.append("utm_medium", getCookie("utm_medium"));

      mutateAsync(formData).then((response) => {
        if (response.success === 1) {
          setThankOpen(true);
          setImageUpload("");
          setValue("");
        }
      });
    }
  };

  const handleChanges = (e) => {
    const data = e.target.value;
    setValue(data);
  };

  const handleUploadImage = (e) => {
    const files = e.target.files[0];
    setImageUpload(files);
  };

  return (
    <div
      className={`${
        backgroundCol ? backgroundCol : "bg-darkBlue"
      } flex items-center justify-center w-full py-24`}
    >
      <div
        className={`flex items-center justify-center flex-col xl:w-6/12 md:w-9/12 px-4 `}
      >
        <h2 className=" md:text-4xl text-2xlpb-10 text-center font-secondary mb-12">
          <span className="text-white md:text-4xl text-2xl  font-bold">
            {firstHead ? firstHead : ""}
          </span>{" "}
          <span
            className={`${
              headColor ? headColor : "text-lightBlue"
            } md:text-4xl text-2xl font-bold`}
          >
            {colHead ? colHead : "Send"}
          </span>{" "}
          <span className="text-white md:text-4xl text-2xl font-bold">
            {secondHead ? secondHead : "us your technical documents"}
          </span>
        </h2>
        <p className="text-darkMist text-center pb-8 leading-loose font-opensans">
          {para
            ? para
            : "It's easy to start. Just attach a document to be translated and the target languages you need. We'll respond immediately."}
        </p>
        <div className="flex items-center justify-between flex-col md:flex-row md:my-10 relative">
          <input
            type="text"
            name="email"
            className="md:w-64 h-12 md:mr-8 mr-0 rounded-sm pl-3 font-opensans md:mb-0 mb-7 w-full"
            placeholder="Enter email address here"
            onChange={handleChanges}
            value={value}
            required
          />

          <input
            type="file"
            onChange={handleUploadImage}
            name="document"
            className="md:w-64 h-12 md:mr-8 mr-0 rounded-sm pl-3 bg-white pt-2 font-opensans md:mb-0 mb-7 w-full"
            placeholder=""
            value={imageupload}
            required
          />

          <button
            type="submit"
            onClick={onFileUpload}
            className="w-28 h-12 sm:mt-0 mt-8 mr-0 uppercase bg-lightBlue font-opensans text-white"
          >
            {status === "loading" ? <Loader /> : "SUBMIT"}
          </button>
          <div className="mb-5 ">
            <div className="flex items-baseline gap-2 absolute sm:top-[65px] top-[135px] left-0">
              <p className="text-sm text-white font-opensans">
                *I agree to Tomedes'&nbsp;
                <Link
                  to="/privacy"
                  className="text-orange hover:text-newOrange-100 underline transition-colors"
                >
                  Privacy Policy
                </Link>
                &nbsp;by submitting this form.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ThankYouPopup
        open={thankOpen}
        setOpen={setThankOpen}
        setOpenPopUp={setOpen}
      />
    </div>
  );
}

export default TechnicalDocument;
