import Reviews from 'components/homepage/reviews'
import ServicesGrid from 'components/homepage/servicesGrid'
import WhatChooseUs from 'components/homepage/whyChooseUs'
import LocationBanner from 'components/locationBanner'
import SpeceficTranslation from 'components/LocationReusableComp/speceficTranslation'
import React from 'react'
import FAQ from '../BirthCertificate/FAQs'
import LocalizationMidText from '../localizationMidText'
import TechnicalDocument from '../technicalDocument'
import VariousTranslation from '../VariousTranslation'
import { TranslationData, SliderData } from './config'
import FifthComp from './fifthComp'
import FourthComp from './fourthComp'
import SecondComp from './secondComp'
import SixthComp from './sixthComp'

export default function TranscriptTranslationServices() {
    return (
        <div>
         <LocationBanner
        title="Transcript Translation Services"
        backgroundCol="bg-midBlue"
        content="Tomedes offers transcript translation services, translating official academic transcripts for high school, college, university and graduate school performance. An official transcript of the academic record of a student is something that school and employers may request to see. If transcripts are in a foreign language, you need to get them translated."
        formOrangeHead="Looking"
        formWhiteHead=" for Transcript Translations?"
      />
      <SecondComp/>
      <LocalizationMidText
      fText="Broaden your horizons with our"
        backgroundCol="bg-midBlue"
        orangeMidText="transcript"
        content="translation service. Our transcript translation services will solve your problems when it comes to applications that are not in your language. Transcripts translations will be authenticated translations, providing you with global solutions to your academic and professional problems."
      />
       <SpeceficTranslation
        FirstOrange=" "
        FirstWhite="Keeping Up with"
        ColorHead="International"
        SecondWhite="Academic Standards"
        headColor="text-lightBlue"
        linkName="SEE OUR SUPPORTED LANGUAGES"
        Content="We ensure that our transcripts translation services adhere to national and international standards of grading systems. There are different grading systems for countries depending on their governments--a country can employ a 4.0 scale, a 1.0 scale, an A scale, and more. These standards are also translatable, in error-free translations, because one error in calculation can cause a high grade to be a low grade. Thatâ€™s precisely why Tomedes transcript translators are required to pass a certain level of expertise when it comes to transcript translation."
        backgroundCol="bg-darkBlue"
        getStarted
      />
      <FifthComp/>
      <SixthComp/>
      <TechnicalDocument
      colHead=" "
        backgroundCol="bg-midBlue"
        secondHead="Stay ahead of the curve"
        para="Let us know if you have any questions regarding our transcript translation services."
      />
      <VariousTranslation
      backgroundCol="bg-darkBlue"
        ftitle=" "
        para=" "
        title="Translated transcripts are commonly used for:"
        data={TranslationData}
      />
      <ServicesGrid
        backgroundCol="bg-midBlue"
        heading="Expertise in multiple fields"
        details="Our transcript translators are versed in translating transcripts worldwide, complete with expert knowledge of the subjects, the grading systems, and the information within the transcripts. Theyâ€™re more than translators, theyâ€™re expert transcript decoders ranging from various fields."
      />
      <FAQ SliderData={SliderData} />
      <WhatChooseUs/>
     <Reviews
       backgroundCol="bg-darkBlue"
     />
        </div>
    )
}
