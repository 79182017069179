import React from 'react'

export default function SecondComp() {
    return (
        <div className='bg-darkBlue'>
            <div className="mainDiv text-white text-center lg:w-5/12 w-10/12 flex-col mx-auto justify-center py-20">
                <h1 className='text-4xl font-bold leading-relaxed'>Transcript Translation Services<span className='text-lightBlue'> Trusted </span>by Academic Institutions Worldwide</h1>
                <p className='py-8 font-light leading-loose'>Our transcript translation service is trusted by academic institutions worldwide. We are ISO-certified to provide transcript translation services, and we provide certification with your translations upon request. Whatever you aspire to be, we can help you in your journey.</p>
            </div>
        </div>
    )
}
