import React , {useState} from 'react'

function FAQ({SliderData}) {
    const [selected , setSelected] = useState(null)
    const [open , setOpen] = useState(false)
    const handleArrow = (id) => {
        setSelected(id)
        setOpen(!open)
    }
    return (
        <div className="flex items-center justify-center bg-darkBlue py-14 md:py-24">
         <div className="flex items-center justify-center max-w-screen-xl px-4 md:px-0 flex-col w-full lg:w-7/12">
            <h2 className="text-center font-secondary font-bold text-orange text-[36px] mb-10">FAQs</h2>     
            <div className="flex items-center justify-center w-full  flex-col ">
                {SliderData ? SliderData.map(({heading ,para , id}) => (
                     <div className="card w-full  flex justify-between mb-2 flex-col" key={id}>
                         <div className="flex py-5 px-4  bg-midBlue justify-between cursor-pointer" onClick={() => handleArrow(id)}>
                         <h3 className={`text-xl  font-bold font-secondary my-auto ${selected === id && open ? "text-orange" : "text-white"}`}>{heading}</h3>
                    <span className={`text-2xl text-white cursor-pointer `} >&#x3e;</span>
                         </div>
                <div className={`transition-all ease-in-out delay-150  duration-300 px-4 md:px-10 ${ selected === id ? "block" : "hidden"}`}>
                    {open ?  <p className="text-darkMist font-opensans leading-8 ">{para}</p> : null}
                   
                </div> 
                </div>
                )) : null}
            </div>
        </div>   
        </div>
    )
}

export default FAQ
