export const TranslationData = [
    {
      title: "University Applications",
      link: " ",
      detail:
        "Transcripts translation is usually needed for higher education, whether it be academic, school, college or university, graduate, postgraduate, or postdoc programs. Where you're studying abroad for the short term, or looking to pursue a degree for the long term, these transcript translations can help you in your aspirations to go to your dream school in your dream program.",
    },
    {
      title: "USCIS Applications      ",
      link: " ",
      detail:
        "For immigration applications to the United States Citizenship and Immigration Services, our international transcript translation services are often a requirement as procedural documents. These transcripts reflect your learnerâ€™s progress in the school youâ€™ve attended, and they may make a study visa or other types of immigration statuses easier to attain. Translate your transcript to English, and your dream destination awaits..",
    },
    {
      title: "Job Applications      ",
      link: " ",
      detail:
        "In order to apply for jobs, you may also need to submit your translated transcript. These requirements are often used to assess your progress in your school, and allow you to be accepted or on-boarded at your dream job. With transcript translations, you can help prepare for your future, even if you have limited proficiency in the language of your employer. ",
    },
    
  ];

  export const SliderData = [
    {
      heading:
        "Are your transcript translation services certified?",
      para: "Certified transcript translation is done by translators as NAATI-certified translators, USCIS certified translators, and more. This official transcript translation service requires the work of translators who have received specialized training and have passed a high level of certification through certificate programs or qualifying exams. You can be sure that your transcript translation services will be verily certified through translation that reflects the original faithfully.",
      id: "112",
    },
    {
      heading: 
      "How can you guarantee the acceptance of my translated transcript?",
      para: "Your translated transcripts are guaranteed to be accepted in all major national and international governmental institutions, universities, or firms because they are done by qualified translators. We guarantee that you will be satisfied with our service through a 1-year guarantee for free, transcript translation has never been easier.",
      id: "223",
    },
    {
      heading: 
      "How much does it cost to translate my transcript?",
      para: "The cost to translate your transcript very much depends on the language pair and the length of your transcript. To get detailed information on how much it would cost to translate your transcript, please follow this link to our quote request page.",
      id: "445",
    },
    
  ];