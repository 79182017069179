import TranscriptTranslationServices from "components/reusableComponents/transcript-translation-services";
import React from "react";
import Layout from "components/layout";
import SEO from "components/seo";

export default function index() {
  const description =
    "Tomedes offers transcript translation services, translating official academic transcripts for high school, college, university and graduate school performance as well as with diplomas.";
  const title = "Transcript Translation Services | Tomedes";
  const keywords = "Transcript Translation Services";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/transcript-translation-services"
      />
      <TranscriptTranslationServices />
    </Layout>
  );
}
